@import "../../../../assets/scss/mixin.scss";
.message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.deletebtn{
    border-radius: 8px;
    background-color: rgb(241, 85, 85);
    border:none;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
  color: #F5F5F5;
  font-weight:bold ;
}
.activity{
    .heading {
        h3 {
            margin-bottom: 15px;
            letter-spacing: 0.9px;
            color: #000;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .card{
        border-radius: 20px;
        background: #F5F5F5;
        padding: 20px;
        border: none;
        color: #98A2B8;
        @media screen and (max-width: $breakpoint_mobile_landscape){
            overflow-x: scroll;
        }
        table{
            width: 100%;
            th{
                border: 0;
                font-size: 18px;
                font-weight: 500;
                color: #000;
                padding: 10px 0;
                text-align: justify;
                padding: 20px 10px;
                white-space: nowrap;

            }
            td{
                border-bottom: 1px solid #E1E1E1;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                padding: 10px 0;
                text-align: justify;
                padding: 20px 10px;
                white-space: nowrap;
                img{
                    width: 20px;
                }
                span{
                    display: flex;
                    align-items: center;
                    img{
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}