@import "../../assets/scss/mixin";
.iconbg{
  color: #000;
  font-size: 30px;
  margin-left: 20px;
}
.panel-wrapper {
  .panel-main-content {
    margin-left: 240px;
    width: calc(100% - 240px);
    padding: 0 0 32px;
    position: relative;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;

    @media screen and (max-width:$breakpoint_tablet) {

      &.hide {
        margin-left: 0;
        width: 100%;
      }

      margin-left: 0;
      width: 100%;
    }

    .panel-header {
      width: inherit;
      height: 76px;
      background: #fff;
      border-bottom: 1px solid transparent;
      padding: 0 25px;
      position: fixed;
      z-index: 99;

      @media screen and (max-width:$breakpoint_tablet) {
        padding: 0 15px;
      }

      .left-side {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .search_bar {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          @media screen and (max-width:$breakpoint_mobile_portrait) {
           display: none;
          }
          @media screen and (max-width:$breakpoint_tablet) {
            margin-left: 50px;
          }

          img {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translate(0px, -45%);
          }

          input {
            border: 1px solid transparent;
            width: 100%;
            padding: 5px 5px 5px 35px;
            height: 48px;
            border-radius: 15px;
            color: #b0b0b0;
            background: #F5F5F5;
            @media screen and (max-width:$breakpoint_mobile_portrait) {
              // width: 165px;
            }

            &::placeholder {
              color: #7B8289;
            }
          }
        }
        .upload{
          margin-right: 10px;
          @media screen and (max-width:$breakpoint_mobile_portrait) {
            // display: none;
       margin-left: 150px;

           }
          button{
            background: #57C1FF;
            border: none;
            padding: 7px 10px;
            width: 150px;
            display: flex;
            color: #fff;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            font-weight: 800;
            img {
              width: 15px;
              margin-right: 7px;
            }
          
          }
        }
      }

      .right-side {
        display: flex;
        align-items: center;

        .dropdown {
          text-align: end;

          .dropdown-toggle {
              background: transparent;
              color: #fff;
              padding: 0;
              border: 0;
              display: flex;
              align-items: center;

              .date {
                display: flex;
                align-items: center;
                  p {
                      color: #000;
                      font-size: 14px;
                      margin-bottom: 0;
                  }

                 img{
                      margin-right: 10px;
                      width: 40px;
                      height: 40px;
                      border-radius: 100px;
                      object-fit: cover;
                      border: 1.5px solid #57C1FF;
                      padding: 3px;
                 }
              }

              &::after {
                  display: none;
              }
          }
          .dropdown-menu{
            background-color: #fff;
            border:1px solid #bbbbbc;
            right: 0 !important;
            left: auto !important;
            a{
              font-size: 14px;
              color: #000;
              &:hover{
                background-color: #F5F5F5;
              }
            }
          }
      }
      .search{
        width: 20px;
        height: 20px;
        margin-left: 15px;
        filter: invert(1);
      }
      }
    }

    .side-menu-btn {
      background: transparent;
      position: fixed;
      left: 15px;
      top: 22px;
      border: 2px solid #e9e9e9;
      border-radius: 5px;
      @include transition;
      display: none;
      z-index: 999;

      @media (max-width: $breakpoint_tablet) {
        display: block;
      }

      img {
        width: 17px;
        height: 23px;
      }
    }

    .panel-main-wrapper {
      min-height: calc(100vh - 108px);
      padding: 20px 25px 0;
      margin-top: 76px;

      @media screen and (max-width:$breakpoint_tablet) {
        padding: 25px 15px 0;
      }

      .panel-card {
        margin: 0 auto 70px;
        width: 100%;

        .panel-body {
          background: #F8F8F8;
          box-shadow: 20px 20px 11px rgba(0, 0, 0, 0.01), 11px 11px 9px rgba(0, 0, 0, 0.05), 5px 5px 7px rgba(0, 0, 0, 0.09), 1px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 24px 16px;
        }
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-radius: 50%;
        border-top: 4px solid #57C1FF;
        width: 60px;
        height: 60px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        position: absolute;
        top: 50%;
        right: 45%;
        transform: translate(-50%, -50%);
      }
      
      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }
}

.table-btn {
  display: inline-block;
  margin: 2px 0 2px 5px;
  background: var(--theme-color);
  font-size: 12px;
  border-radius: .3rem;
  border: none;
  letter-spacing: 1px;
  white-space: nowrap;
  color: var(--white-color);
  line-height: 30px;
  padding: 0 15px;
  @include transition;

  i {
    margin-right: 5px;
  }

  &:hover {
    color: #ffffff !important;
    background: var(--theme-color);
  }
}

.header-search {
  position: relative;
  margin-right: 10px;
  width: 275px;

  input {
    width: 100%;
    height: 40px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 38px;
    outline: none;
    background: #ffff;
    border: 2px solid #EFEEF1;
    border-radius: 6px;
    font-size: 14px;
    color: #8A8A8A;

    &::placeholder {
      color: #8A8A8A
    }
  }

  button {
    background: #0A2558;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    img {
      width: 20px;
    }
  }
}