
.error{
  margin-top:10px;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  background-color: rgba(231, 91, 91, 0.171);
  font-weight: 500;

}
.loader {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-content {
  background: #fff; // Dark background for content
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); // Enhanced shadow
  max-width: 500px;
  transform: translateY(-30px); // Adjusted to match the slide effect
  animation: slideIn 0.3s forwards;
  .head{
    display: flex;
    align-items: center;
    gap: 15px;
    background: rgba(87, 193, 255, 1);
    padding: 10px 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    img{
      width: 55px;
    }
    h2{
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
    }
    p{
      margin: 0;
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
    }
  }
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px;
}
.innerContent{
  padding: 20px 20px 0;
  .inputBox{
    padding: 20px;
    border: 3px dashed #57c1ff;
    border-radius: 15px;
    text-align: center;
    img{
      width: 30px;
    }
    h3{
      color: rgba(41, 45, 50, 1);
      margin: 20px 0 0;
      font-size: 16px;
    }
    p{
      color: rgba(169, 172, 180, 1);
      margin: 0;
      font-size: 14px;
    }
    button{
      background: #fff;
      color: rgba(84, 87, 92, 1);
      border: 1.51px solid rgba(203, 208, 220, 1);
      border-radius: 8px;
      padding: 6px 20px;
      cursor: pointer;
      font-weight: 600;
      transition: background-color 0.3s, transform 0.2s;
      margin-top: 20px;
    }
  }
  padding: 20px 20px 0;

  .uploadedFiles {
    max-height: 200px; // Maximum height for the scrollable area
    overflow-y: auto;  // Scrollable when content exceeds height
    margin-top: 10px;
    padding-right: 10px;

    .uploadedFile {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      background: rgba(245, 245, 245, 1);
      padding: 20px;
      align-items: flex-start;
      border-radius: 12px;
      margin-bottom: 10px; // Space between files

      .file {
        display: flex;
        gap: 10px;
        align-items: center;

        img {
          width: 50px;
        }

        p {
          color: rgba(169, 172, 180, 1);
          margin: 0;
          font-size: 14px;
          img {
            width: 15px;
            margin: 0 5px;
          }
        }

        h5 {
          color: rgba(41, 45, 50, 1);
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }
}
.modal-actions button {
  padding: 6px 20px;
  border: none;
  border-radius: 8px; // Rounded corners
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.modal-actions button:first-child {
  background: #fff; // Primary button color
  color: rgba(84, 87, 92, 1);
  border: 1px solid #57c1ff;
}

.modal-actions button:last-child {
  background: #57c1ff; // Secondary button color
  color: #fff;
  border: 1px solid #57c1ff;
}

.modal-actions button:hover {
  background-color: #57c1ff23;
  color: #57c1ff; // Darker shade on hover
}
