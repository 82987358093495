.userManagement {
  .dots {
    cursor: pointer;
    position: relative;  // Keep this to ensure the dropdown is positioned relative to the dots
    z-index: 1;  // Ensure the dots remain behind the dropdown when clicked
  }

  .dropdown {
    position: absolute;
    right: 0;  // Align the dropdown to the right side of the parent element
    top: 25px;  // Adjust this value to ensure the dropdown appears below the dots
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;  // Ensure dropdown appears above the dots and other content
    width: 80px;
    

    button {
      display: block;
      width: 100%;
      padding: 4px;
      background-color: rgb(248, 106, 106);
      border: none;
      text-align: left;
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      border-radius: 8px;
      color: #F5F5F5;

      &:hover {
        background-color: rgb(254, 58, 58);
      }
    }
  }

  .message {
    margin-bottom: 15px;
    padding: 10px;
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    text-align: center;
  }

  .message.error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }
}



.userManagement{
    .card{
        background-color: #F5F5F5;
        border: none;
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 20px;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            span{
                color: #fff;
                font-weight: 500;
                background-color: #57C1FF;
                padding: 5px 13px;
                border-radius: 6px;
                font-size: 12px;
                line-height: normal;
            }
            img{
                width: 18px;
                filter: brightness(0.5);
            }
        }
        .user{
            text-align: center;
            img{
                width: 80px;
                height: 80px;
                border-radius: 100%;
                object-fit: cover;
                margin-bottom: 20px;
            }
            h5{
                color: #000;
                font-weight: 700;
                margin-bottom: 0px;
                line-height: normal;
            }
            p{
                color: #000000;
                font-size: 14px;
                line-height: normal;
                margin-bottom: 0;
            }
        }
    }
}