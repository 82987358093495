@import "../../assets/scss/mixin";

.panel-sidebar {
  z-index: 99;
  width: 240px;
  height: 100vh;
  background: #fff;
  padding: 25px 40px 15px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  @include transition;

  @media screen and (max-width:$breakpoint_tablet) {

    &.show {
      width: 240px;
      top: 76px;
    }

    &.hide {
      width: 0px;
      padding: 0;
      overflow: hidden;
      top: 76px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: .3rem;
  }

  .ps-sidebar-root {
    border-color: transparent;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .ps-sidebar-container {
    background-color: transparent;
    overflow: inherit;
    .logo{
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      margin-left: 10px;
      img{
        width: 165px;
      }
      h5{
        margin: 0 0 0 10px;
        word-break: keep-all;
        font-size: 16px;
      }
    }
  }

  ul {
    &.main-menu {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .ps-submenu-root {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 5px;

      &.active-link {
        background-color: #57C1FF;
          border-radius: 0px 10px 10px 0;
          color: #000;

        img {
          filter: sepia(1);
        }
        p{
          color: #fff;
          font-weight: 800;
        }
      }

      &:hover {
        background-color: #57C1FF;
          border-radius: 0px 10px 10px 0;

        img {
          filter: none;
        }
      }

      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }

      .ps-submenu-content {
        margin-top: 16px;

        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .ps-menu-button {
        padding-left: 30px;
        padding-right: 10px;
        height: auto;
       
        span{
          display: flex;
        }

        &:hover {
          background-color: #57C1FF;
          border-radius: 0px 10px 10px 0;
          p{
            color: #fff;
            font-weight: 800;
          }
          img {
            filter: sepia(1);
          }
        }

      }

      .ps-submenu-expand-icon {
        margin-right: 5px;
      }

      a {
        display: flex;
        font-size: 16px;
        align-items: center;
        color: #0A2558;
        padding: 8px;
        font-weight: 500;
        white-space: nowrap;
        @include transition;

        .ps-menu-icon {
          margin-right: 0;
        }

        img {
          width: 16px;
          filter: invert(1);
          margin-right: 10px;
        }
        p{
          margin: 0;
          font-size: 14px;
          color: #000000;
        }

        &.ps-menu-icon {
          justify-content: flex-start;
        }
      }
    }
  }
}
.toggle-sidebar {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #57C1FF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 100;
}