@import "../../../../assets/scss/mixin.scss";


#chart{
   .card{
    background: #F5F5F5;
    padding: 10px 15px 0px;
    border-radius: 15px;
    margin-bottom: 20px;
    border: 1px solid #F5F5F5;

    @media screen and (max-width:$breakpoint_mobile_portrait) {
padding: 30px 10px 0px;       }

    .apexcharts-toolbar,
    .apexcharts-xaxis,

    .apexcharts-xaxis-tick {
      display: none;
    }

    .apexcharts-active,
    .apexcharts-xaxistooltip-text,
    .apexcharts-tooltip-title {
      background-color: #F5F5F5;
      color: #000;
    }
    .apexcharts-text tspan {
        font-family: "Inter", sans-serif;
        color: #98A2B8;
        font-size: 14px;
      }
    .dropdowns{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sale{
          display: flex;
          align-items: center;
          gap: 20px;
          h4{
            font-size: 18px;
            margin: 0;
            color: #000;
          }
          p{
            margin: 0 10px;
            display: flex;
            align-items: center;
            img{
              margin-right: 15px;
              &:last-child{
                margin-left: 15px;
                transform: rotate(180deg);
              }
            }
          }
        }
        .dropdown {
            text-align: end;
            margin-right: 5px;
            margin-left: 20px;
            .dropdown-toggle {
                background: transparent;
                color: #F7F8FA;
                display: flex;
                align-items: center;
                border-radius: 7px;
                border: none;
                padding: 3px 10px;
                .date {
                  display: flex;
                  align-items: center;
                    p {
                        color: #000;
                        font-size: 14px;
                        margin-bottom: 0;
                    }
    
                   img{
                        margin-right: 10px;
                        width: 40px;
                        height: 40px;
                        border-radius: 100px;
                        object-fit: cover;
                        border: 1.5px solid #5E196C;
                        padding: 3px;
                   }
                }
    
                &::after {
                    border: 0;
                    background: url(../../../../assets/images/down-angle-arrow.svg) no-repeat center;
                    background-size: contain;
                    width: 10px;
                    height: 9px;
                    margin-left: 12px;
                }
            }
            .dropdown-menu{
             
              a{
                font-size: 14px;
                color: #7B8289;
                &:hover{
                
                }
              }
            }
        }
    }
    .months{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      p{
        margin: 0;
        &:first-child{
          &::before{
            content: "";
            width: 17px;
            height: 17px;
            background: #FF45D6;
            border-radius: 4px;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: -2px;
          }
        }
        &:last-child{
          margin: 0;
          &::before{
            content: "";
            width: 17px;
            height: 17px;
            background: #57C1FF;
            border-radius: 4px;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: -2px;
          }
        }
      }
    }
   }
}