@import "../../../../assets/scss/mixin.scss";

.courseCards {
    .heading {
        h3 {
            margin-bottom: 15px;
            letter-spacing: 0.9px;
            color: #000;
            font-weight: 500;

        }
    }
    .cards{
        display: flex;
        align-items: center; 
        gap: 20px;
        @media screen and (max-width: 600px) {
            margin-bottom: 20px;
        }       
        .card {
            width: 50%;
            padding: 20px;
            background-color: #F5F5F5;
            border: none;
            border-radius: 15px;
            position: relative;
            overflow: hidden;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            height: 237px;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
            img{
                width: 90px;
                height: 90px;
                margin: 0 auto 20px;
            }
            h3 {
                font-size: 16px;
                margin-bottom: 0px;
                color: #000;
            }
            p {
                margin: 0;
                font-size: 12px;
                color: #000000;
            }
    
        }
    }
}
